import Helpers from './Helpers.js';
import EnvManager from './EnvManager.js';

export default class DataProvider{
    constructor(){
    }

    static typesOfTrainings = [
        { value: 'Calisthenics', label: 'Calisthenics' },
        { value: 'Weight lifting', label: 'Weight lifting' },
        { value: 'Mixed', label: 'Mixed' }
    ]

    static async getSchedule(scheduleUuid) {
        const IDtoken = localStorage.getItem('IDtoken')
        const response = (localStorage.getItem(`schedule_${scheduleUuid}`));
        try {
            const response = await fetch(`${EnvManager.API_URL}/api/schedule/${scheduleUuid}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${IDtoken}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error('Can not get schedule, sorry', scheduleUuid);
        }
    }
    static async getAllSchedules() {
        const IDtoken = localStorage.getItem('IDtoken')
        try {
            const response = await fetch(`${EnvManager.API_URL}/api/schedule?view=brief`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${IDtoken}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error('Can not get schedules, sorry', scheduleUuid);
        }
    }
    // post - new schedule, put - resave old schedule
    static async setSchedule(schedule) {
        const IDtoken = localStorage.getItem('IDtoken')
        if ('uuid' in schedule) {
            try {
            const response = await fetch(`${EnvManager.API_URL}/api/schedule/${schedule.uuid}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${IDtoken}`
                },
                body: JSON.stringify(schedule)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
            } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error('Can not update schedule, sorry');
            }
        } else {
            try {
            const response = await fetch(`${EnvManager.API_URL}/api/schedule/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${IDtoken}`
                },
                body: JSON.stringify(schedule)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
            } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error('Can not save schedule, sorry');
            }
        }
    }
    static async deleteSchedule(scheduleUuid) {
        const IDtoken = localStorage.getItem('IDtoken')
        try {
            const response = await fetch(`${EnvManager.API_URL}/api/schedule/${scheduleUuid}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${IDtoken}`
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
            } catch (error) {
            console.error('Error fetching data:', error);
            throw new Error('Can not delete schedule, sorry');
            }
    }
/////////////////////////////
    static addNewTraining(schedule) {
        const id = Helpers.createId(10)
        schedule.trainings.push({ "id":  Helpers.createId(10) , "data": { "date": null, "type": null, "intensity": null, "time": null }, "exercises": [ ] })
    }

    static deleteTraining(schedule, trainingId) {
        schedule.trainings = schedule.trainings.filter(training => training.id !== trainingId)
    }

    static async getTraining(scheduleUuid, trainingId) {
        const schedule = await this.getSchedule(scheduleUuid)
        const training = schedule.trainings.find(item => item.id === trainingId)
       /* if (typeof training.data.date !== null){
            training.data.date = (new Date(training.data.date)).toLocaleDateString('en-US')
        }*/
        return training 
    }

    static async setTraining(scheduleUuid, training) {
        training.data.date = (new Date(training.data.date)).getTime()
        let schedule = await this.getSchedule(scheduleUuid)
        const index = schedule.trainings.findIndex(item => item.id === training.id);
        schedule.trainings[index] = training;
        this.setSchedule(schedule)
    }
/////////////////////////

    static addNewExerciseAnaerobic(training) {
        training.exercises.push({ "id": Helpers.createId(10), "data": { "name": null, "type": "anaerobic", "parameters": { "sets_reps": null, "weight": null, "recovery": null } } })
    }
    static addNewExerciseAerobic(training) {
        training.exercises.push({ "id": Helpers.createId(10), "data": { "name": null, "type": "aerobic", "parameters": { "distance": null, "duration": null, "sets": null } } })
    }

    static deleteExercise(training, exerciseId) {
        training.exercises = training.exercises.filter(exercise => exercise.id !== exerciseId)
    }

    }


