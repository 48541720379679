<template>
    <template v-if="authorized">
        <router-view/>
    </template>
    <template v-if="!authorized">
        <SignInView/>
    </template>
</template>

<script>
import SignInView from './views/SignInView.vue'
export default {
    data(){
        return{
            authorized: null
        }
    },
    components: {
        SignInView
    },
    async mounted(){
        const q = new URLSearchParams(document.location.hash.slice(1))
        if (q.has('id_token')) {
            localStorage.setItem("IDtoken", q.get('id_token')) 
        }
        const IDtoken = localStorage.getItem("IDtoken")
        this.authorized = IDtoken
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@200;400&display=swap');
  body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}
  table{border-collapse:collapse;border-spacing:0}
  fieldset,img{border:0}
  address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}
  ol,ul{list-style:none}
  caption,th{text-align:left}
  h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}
  q:before,q:after{content:''}
  abbr,acronym{border:0;font-variant:normal}
  sup{vertical-align:text-top}
  sub{vertical-align:text-bottom}
  input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit}
  input,textarea,select{font-size:100%}
  legend{color:#000}
  html,body{height: 100%; width: 100%;}
 
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 5px solid #f3f3f3; /* Light grey */
        border-top: 5px solid #E45490; /* Blue */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        50% { transform: rotate(180deg); }
        100% { transform: rotate(360deg); }
    }
    body{
        font-family: 'Geologica', sans-serif;
        --gapForSections: 16px;
        height: auto;
        min-height: 100%;
        display: flex;
    }
    #app{
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgb(242, 242, 242);
    }
            #app > header{
                display: flex;
                align-items: center;
                padding: 0 4.375rem 0 4.375rem;
                height: 6.75rem;
                min-height: 6.75rem;
                background-color: white;
            }
            #app > header > img{
                height:3rem;
                padding-left: 1rem;
            }
            #content{
                box-sizing: border-box;
                flex: 1;
                background-color: rgb(242, 242, 242);             
                margin: 0 auto;
                width: 80%;
            }

            .section1, .section2,.section3{
                margin-bottom: 2rem;
                
            }
            .section1{
                padding: 32px 2px 0 20px;
                display: flex;
                justify-content: space-between;
                height: 40px;
            }
            .section1 > nav {
                color: #AEAEAE;
                display: flex;
                align-items: center;
            }
            .section1 > nav > p.nav_arrow{
                    display: none;
                }
            .section1 > nav > a {
                text-decoration: none;
                color: #094C59;
                padding: 0 4px 0 4px;
            }
            .section1 > nav > a:first-of-type {
                padding-left: 0;
            }
            .section1 > nav > a.current_nav_element{
                color: #E45490;
            }
            .section1 > button{
                height: 2.5rem;
                width: 5.5rem;
                font-size: 1.125rem;
                border: 0;  
                background-color: rgb(0, 170, 255);  
                color: rgb(255, 255, 255);
                text-transform: uppercase;
                font-weight: bold;
            }
            .new_element_button:hover, .section1 > button:hover {
                background: #e5b000;
            }
            .section2{
                box-shadow: 0.25rem 0.25rem 0px 0px #00000040;
            }
            .section2 > #form_training{
                display: flex;
                background-color: rgb(255, 255, 255);
                padding: 1rem;
                gap: var(--gapForSections);
            }
            .section2 > #form_training > *{
                flex: 0.25;
                display: flex;
                flex-direction: column;
            }
            .section2 > #form_training > div > input{
                padding-bottom: 0.25rem;
                height: 1.5rem;
                width: 100%;
                font-size: 1.25rem;
                border-top: none;
                border-right: none;
                border-left: none;
                border-bottom: #AEAEAE solid 2px;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                vertical-align:top;
            }
            .section2 > #form_training > div > select{
                width: 100%;
                font-size: 1.25rem;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                vertical-align:top;
                border-bottom: #AEAEAE solid 2px;
                line-height: 1.5rem;
                height: 1.8rem;
                margin-top: 1px;
                padding-bottom: 3px;
            }
            .section2 > #form_training > div > input:focus,
            .section2 > #form_training > div > select:focus{
                border-color: #118DA6;
            }
            .section2 > #form_training > div > label{
                font-size: 0.75rem;
                color: #AEAEAE;
                padding-top: 0.25rem;
            }
            ::placeholder, .pseudoPlaceholder{
                color: #AEAEAE;
            }
            .section3{
                display: flex;
                flex-direction: column;
                background-color: rgb(255, 255, 255);
                box-shadow: 0.25rem 0.25rem 0px 0px #00000040;
            }
            .section3 input:disabled {
                background-color: white;
            }
            .section3 select.placeholder_selected, .section2   select.placeholder_selected {
                color: #AEAEAE;
            }
            .section3 select.placeholder_selected option,.section2 select.placeholder_selected option {
                color: black
            }
            .section3 select.option_selected, .section2 select.option_selected {
                color: black;
            }
            .section3 .pseudo_table_head {
                display: flex;
                flex-direction: row;
            }
            .section3 .pseudo_table_head_cell {
                padding-left: 1rem;
                font-size: 0.75rem;
                color: #AEAEAE;
                display: flex;
                align-items: center;
            }
            .section3 .pseudo_table_row, .section3 .pseudo_table_head {
                border-bottom: 1px solid #E6E6E6;
                border-collapse: collapse;
                height: 2.8rem;
                display: flex;
                flex-direction: row;
            }
            .section3 .pseudo_table_cell, .section3 .pseudo_table_head_cell  {
                padding-left: 1rem;
                flex: 0.25;
            }
            .section3 .pseudo_table_cell_delete {
                display: flex;
                align-items: center;
                justify-content: right;
                padding-right: 4px;
                width: 50px;
            }
            .section3 .pseudo_table_cell input, .section3 .pseudo_table_cell p{
                border: none;
                font-size: 1.25rem;
                outline: none;
                width: 100%;
                height: 2.5rem;
                padding-top: 0.3rem;
            }
            .section3 .pseudo_table_cell p{
                display: flex;
                align-items: center;
            }
            .section3 select:focus, .section3 input:focus{
                box-shadow: 0 1px 0 0 #00AAFF;
            }
            button {
                cursor: pointer;
            }
            .add_new_table_element_button{
                height: 3rem;
                font-size: 1rem;
                border: none;
                background-color: inherit;
                color: #00AAFF;
                text-align: left;
                padding-left: 1rem;
                font-family: inherit;
            }
            .edit_button {
                font-size: 1.25rem;
                border: none;
                background-color: inherit;
                color: #00AAFF;
                text-align: left;
            }
            .delete_button{
                color: #D90B61;
                border: none;
                background-color: inherit;
                font-size: 1rem;
            }
            .delete_button_table_container{
                height: 2.5rem;
                align-items: center;
                display: flex;
                justify-content: flex-end;
            }
            .section3 label {
                display: none;
            }
            @media screen and (max-width: 960px) {
                #app > header {
                    padding: 0 16px 0 16px;
                }
                .section2 > #form_training > * {
                   width: calc(25% - var(--gapForSections));
                }
                .section2 > #form_training > div > input, .section2 > #form_training > div > select {
                    font-size: 14px;
                }
                .section3 .pseudo_table_cell > input, .section3 .pseudo_table_cell > p {
                    font-size: 14px;
                }
            }    
            @media screen and (max-width: 640px) {
                .section1 {
                    padding-left: 0;
                }
                .section1 > button {
                    background-color: rgb(0, 0, 0, 0.8);
                    font-size: 24px;
                    width: 100%;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    animation-name: saveButtonPopUp;
                    animation-duration: 2s;
                    z-index: 10000;
                }
                @keyframes saveButtonPopUp {
                    from {bottom: -100px;}
                    to {bottom: 0;}
                }
                .section1 > a {
                    font-size: 14px;
                }
                .section2 > #form_training {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr;
                    gap: var(--gapForSections);
                    padding: 8px;
                }
                .section2 > #form_training > * {
                    min-width: 100%;
                }
                .section2 > #form_training > div > input, .section2 > #form_training > div > select {
                    font-size: 16px;
                }
                .section3 .pseudo_table_head {
                    display: none;
                }
                .section3 .pseudo_table_row {
                    padding: 24px 8px 8px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr;
                    gap: var(--gapForSections);
                    position: relative;
                    height: auto;
                }
                .section3 .pseudo_table_cell {
                    padding: 0;
                }
                .section3 .pseudo_table_cell_delete {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .section3 .pseudo_table_cell > input, .section3 .pseudo_table_cell > p {
                    padding: 0;
                    border-bottom: #AEAEAE solid 2px;
                    font-size: 16px;
                }
                .section3 select:focus, .section3 input:focus{
                    border-color: #00AAFF;
                    box-shadow:none;
                }
                .section3 label {
                    display: block;
                    font-size: 0.75rem;
                    color: #AEAEAE;
                    padding-top: 0.25rem;
                }
            }
            @media screen and (max-width: 500px) {
                .section1 {
                    padding-left: 0;
                    flex-direction: column;
                    height: auto;
                    align-items: center;
                }
                .section1 > nav {
                    flex-direction: column;
                    align-items: baseline;
                    align-items: center;
                }
                .section1 > nav > a {
                    box-sizing: border-box;
                    padding: 8px;
                    font-size: 16px;
                    min-width: 96px;
                    text-align: center;
                }
                .section1 > nav > a:first-of-type {
                    padding-left: 8px;
                }
                .section1 > nav > p.nav_slash{
                    display: none;
                }
                .section1 > nav > p.nav_arrow{
                    color: black;
                    display: block;
                }
            }
            @media screen and (max-width: 320px) {
                #app > header {
                    padding: 0;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }
                #app > header > img {
                    padding: 0;
                    height: 32px;
                }

                .section2 > #form_training {
                    padding: 8px;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 1fr 1fr 1fr; 
                }
                .section2 > #form_training > div > select, .section2 > #form_training > div > input {
                    padding-bottom: 0;
                }
                .section3 .pseudo_table_row {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 1fr 1fr 1fr;
                }
                .section3 .pseudo_table_cell > input, .section3 .pseudo_table_cell > p {
                    font-size: 16px;
                    height: 24px;
                }
                .section3 .pseudo_table_cell_delete {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .section3 .add_new_table_element_button{
                    font-size: 12px;
                }

            }

</style>
