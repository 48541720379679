<template>
<header>
    <img src="../assets/img/logo_pink.svg" alt="Fizzrook">
</header>

<div class="loader" v-if="!this.training"></div>

<div v-else id="content" v-if="schedule && training">
    <section class="section1">
     <nav>
        <router-link to="/">My Schedules</router-link>
        <p class="nav_slash">/</p><p class="nav_arrow">▼</p>
        <router-link :to="`/${scheduleUuid}`"> {{ this.schedule.data ? this.schedule.data.name : 'Loading...' }} </router-link>
        <p class="nav_slash">/</p><p class="nav_arrow">▼</p>
        <router-link :to="`/${scheduleUuid}/${trainingId}`" class="current_nav_element">Training {{ trainingDateModel ? trainingDateNav(trainingDateModel) : 'loading...' }}</router-link>
    </nav>
        <button @click="saveTraining" v-show="isTrainingNotEqualSavedTraining || dateChanged">
            Save
        </button>
    </section>
    <section class="section2">
        <form id="form_training" v-if="training.data" action="">
            <div>
                <input v-model="trainingDateModel" @change="showSaveButton()" type="date" id="input_training_date" name="input_training_date" >
                <label for="input_training_date">Training date</label>
            </div>
            <div id="shitty_select_div">
                <select v-model="training.data.type"  :class="training.data.type === null ? 'placeholder_selected' : 'option_selected'" name="input_training_type" id="select_training_type">
                    <option :value="null" disabled hidden >Calisthenics</option>
                    <option v-for="option in typesOfTrainings" v-bind:key="option.value" :value="option.value" >{{ option.value }}</option>
                </select>
                <label for="input_training_type">Type {{ training.data.type }}</label>
            </div>
            <div>
                <input v-model="training.data.intensity"  type="number" placeholder="5" min="0" max="10" id="input_intensity" name="input_intensity">
                <label for="input_intensity">Intensity from 0 to 10</label>
            </div>
            <div>
                <input v-model="training.data.duration"  type="number" placeholder="60" id="input_training_length" name="input_training_length">
                <label for="input_training_length">Training length, minutes</label>
            </div>
        </form>
    </section>
    <section class="section3">
        <!--ANAEROBIC TABLE-->
        <div id="pseudo_table_anaerobic" class="pseudo_table">
            <div class="pseudo_table_head" v-if="this.anaerobicExercises.length > 0">
                <span class="pseudo_table_head_cell">Exercise</span>
                <span class="pseudo_table_head_cell">Sets/Reps</span>
                <span class="pseudo_table_head_cell">Weight</span>
                <span class="pseudo_table_head_cell">Recovery time</span>
                <span class="pseudo_table_cell_delete"></span>
            </div>
            <div class="pseudo_table_row" v-for="exercise in this.anaerobicExercises">
                <div class="pseudo_table_cell">
                    <input type="text"  spellcheck="false" v-model="exercise.data.name">
                    <label>Exercise</label>
                </div>
                <div class="pseudo_table_cell">
                    <input type="text"  v-model="exercise.data.parameters.sets_reps">
                    <label>Sets/Reps</label>
                </div>
                <div class="pseudo_table_cell">
                    <input type="text"  v-model="exercise.data.parameters.weight">
                    <label>Weight</label>
                </div>
                <div class="pseudo_table_cell">
                    <input type="text"  v-model="exercise.data.parameters.recovery">
                    <label>Recovery</label>
                </div>
                <div class="pseudo_table_cell_delete">
                    <button class="delete_button" @click = "deleteExercise(training, exercise.id);">✕</button>
                </div>
            </div>
        </div>
        <div>
        <button class="add_new_table_element_button" @click = "addNewExerciseAnaerobic()">Add new anaerobic exercise</button>
        </div>
        <!--AEROBIC TABLE-->
        <div id="pseudo_table_aerobic" class="pseudo_table">
            <div class="pseudo_table_head" v-if="this.aerobicExercises.length > 0">
                <span class="pseudo_table_head_cell">Exercise</span>
                <span class="pseudo_table_head_cell">Distance</span>
                <span class="pseudo_table_head_cell">Duration</span>
                <span class="pseudo_table_head_cell">Sets</span>
                <span class="pseudo_table_cell_delete"></span>
            </div>
            <div class="pseudo_table_row" v-for="exercise in this.aerobicExercises">
                <!--filter instead of v-if-->
                <div class="pseudo_table_cell">
                    <input type="text"  spellcheck="false" v-model="exercise.data.name">
                    <label>Exercise</label>
                </div>
                <div class="pseudo_table_cell">
                    <input type="text"  v-model="exercise.data.parameters.distance">
                    <label>Distance</label>
                </div>
                <div class="pseudo_table_cell">
                    <input type="text"  v-model="exercise.data.parameters.duration">
                    <label>Duration</label>
                </div>
                <div class="pseudo_table_cell">
                    <input type="text"  v-model="exercise.data.parameters.sets">
                    <label>Sets</label>
                </div>
                <div class="pseudo_table_cell_delete">
                    <button class="delete_button" @click = "deleteExercise(training, exercise.id);">✕</button>
                </div>
            </div>
        </div>
        <div>
        <button class="add_new_table_element_button" @click = "addNewExerciseAerobic()">Add new aerobic exercise</button>
        </div>
    </section>
</div>
</template>


<script>
import DataProvider from '../DataProvider'
import Helpers from '../Helpers'
    export default {    
        data() {
            return {
                schedule: null,
                training: null,
                trainingId: null,
                scheduleName: null,
                typesOfTrainings: DataProvider.typesOfTrainings,
                trainingDateModel: null,
                savedTraining: {},
                dateChanged: false
            }
        },
        async mounted() {
            this.scheduleUuid = this.$route.params.scheduleUuid
            this.trainingId = this.$route.params.trainingId
            this.training = await DataProvider.getTraining(this.scheduleUuid, this.trainingId)
            this.schedule = await DataProvider.getSchedule(this.scheduleUuid)
            this.savedTraining = Helpers.deepCopy(this.training)
            this.trainingDateModel =  this.training.data.date ? this.formatDateFromTimestamp(this.training.data.date) : this.formatDateFromTimestamp(Date.now())
        },
        methods: {
            showSaveButton() {
                this.dateChanged = true
            },
            hideSaveButton() {
                this.dateChanged = false
            },
            formatDateFromTimestamp(timestamp) {
                const date = new Date(timestamp);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, add 1
                const day = String(date.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;
                return formattedDate;
            },
            addNewExerciseAerobic() {
                DataProvider.addNewExerciseAerobic(this.training)
            },
            addNewExerciseAnaerobic() {
                DataProvider.addNewExerciseAnaerobic(this.training)
            },
            deleteExercise(training, exerciseId) {
                DataProvider.deleteExercise(training, exerciseId)
            },
            async saveTraining() {
                let content = document.getElementById('content')
                content.style.pointerEvents = 'none'
                this.training.data.date = new Date(this.trainingDateModel + ' 12:00:00').getTime()
                await DataProvider.setTraining(this.scheduleUuid, this.training)
                this.savedTraining = Helpers.deepCopy(this.training)
                this.hideSaveButton()
                content.style.pointerEvents = 'auto'
            },
            trainingDateNav(inputDate) {
                const parts = inputDate.split('-');
                const year = parseInt(parts[0], 10); // Subtract 1 from the year
                const month = parts[1];
                const day = parts[2];

                const transformedDate = `${month}/${day}/${year}`;
                return transformedDate;
            }
        },
        computed: {
            isTrainingNotEqualSavedTraining() {
                return !Helpers.deepEqual(this.training, this.savedTraining);
            },
            anaerobicExercises() {
                return this.training.exercises.filter(e => e.data.type === 'anaerobic');
            },
            aerobicExercises() {
                return this.training.exercises.filter(e => e.data.type === 'aerobic');
            },
        }
    }
</script>

<style scoped>

</style>