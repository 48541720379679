<template>
    <div id="welcome_screen">
        <div id="overlay" >
            <header>
                <p id="p1">welcome to</p>
                <img id="logo" src="../assets/img/logo.svg" alt="logo">
                <p id="p2">fitness tracker</p>
            </header>
            <div class="add_new_table_element_button">
                <a id="sign_in_button" :href="AuthUrl">SIGN IN</a>
            </div>
        </div>
        </div>
</template>

<script>
    import EnvManager from '../EnvManager'
    export default {
        data(){
        return{
            AuthUrl: ''
        }
    },
    mounted() {
        this.AuthUrl = EnvManager.AUTH_URL
    }
    }
</script>

<style scoped>
    div .add_new_table_element_button {
        margin: auto;
        height: 3rem;
        width: 20rem;  
        font-size: 1.5rem;
        box-shadow: 0.25rem 0.25rem 0px 0px #00000040;
        border: 0;  
        background-color: rgb(0, 170, 255);  
        padding: 0;
    }
    #sign_in_button {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
    }
    #overlay {
            width: 100%;
            min-height: 100%;
            background-color: rgba(0, 0, 0, 0.4); 
            display: flex;
            flex-direction: column;
        }
        #welcome_screen{
            width: 100%;
            min-height: 100%;
            background-image: url('../assets/img/background_cropped.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center left;
            background-attachment: fixed;
        }

        header{
            max-height: 15%;
            display: flex;
            justify-content: space-around;
            margin-top: 3rem;
        }
        header > p{
            color: rgb(255, 255, 255, 0.3);
            flex: 1;
            padding-top: 1.5rem;
            font-size: 2rem;
            
        }
        #p1{
            text-align: right;
        }
        #p2{
            text-align: left;
        }
        #logo{
            width: 20rem;
            padding: 0 1rem 0 1rem;
        }
        @media screen and (max-width: 900px) {
            header {
                flex-direction: column;
                align-items: center;
            }
            header > p {
                padding: 0;
            }
        }
        @media screen and (max-width: 360px) {
            header > #logo {
                width: 180px;
            }
            header > p {
                font-size: 20px;
            }
            #newScheduleFormDiv {
                width: 80%;
            }
            div .add_new_table_element_button {
                font-size: 20px;
                width: 80%;
            }
            
        }
</style>