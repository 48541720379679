<template>
    <div id="welcome_screen">
            <div id="overlay" >
                <header @click="hideForm">
                    <p id="p1">welcome to</p>
                    <img id="logo" src="../assets/img/logo.svg" alt="logo">
                    <p id="p2">fitness tracker</p>
                </header>
                <NewScheduleForm v-if="isFormVisible"/>
                <div class="loader" v-if="!this.schedulesList"></div>
                <div id="welcome_screen_content" @click="hideForm" v-else>
                    <button class="new_element_button" @click.stop="showForm">
                        new schedule
                    </button>
                    
                    <ul id="schedules_list">
                        <li v-for="item of schedulesList" @click="goToSchedule(item.uuid)">
                            <div>
                                {{ item.data.name }}
                                <p class="">
                                    <span v-if="item.last_training_timestamp === null">No trainings yet</span>
                                    <span v-else>Last training: {{ (new Date(item.last_training_timestamp)).toLocaleDateString('en-US') }}</span>
                                </p>
                            </div>
                            <div class="delete_schedule_button_container">
                                <button class = "delete_schedule_button" @click.stop = "deleteSchedule(item.uuid)">&#x271B</button>
                            </div>
                        </li>
                            <!--
                        <li>No Agony, No Bragony
                            <p>No trainings yet</p></li>
                        <li>Piston-Popping
                            <p>Last training: 1/156/2023</p></li>
                        <li>Toss Your Cookies
                            <p>Last training: 7/30/2022</p></li>
                        <li>Toss Your Cookies
                            <p>Last training: 7/30/2022</p></li>
                            -->
                    </ul>
                </div>
            </div>
    </div>
</template>
<script>
import NewScheduleForm from '../components/forms/NewScheduleForm.vue';
import DataProvider from '../DataProvider'
export default {
  data() {
    return {
        retrievedSchedules: [],
        isFormVisible: false,
        schedulesList: null
    };
  },
  components: {
    NewScheduleForm
  },
  methods: {
    showForm() {
      this.isFormVisible = true;
    },
    hideForm() {
        this.isFormVisible = false
    },
    goToSchedule(uuid) {
        this.$router.push({ name: 'ScheduleView', params: { scheduleUuid: uuid } });
    },
    deleteSchedule(scheduleUuid) {
        console.log(scheduleUuid);
        DataProvider.deleteSchedule(scheduleUuid)
        this.schedulesList = this.schedulesList.filter(item => item.uuid !== scheduleUuid)
    }
  },
  async mounted() {

        const data = await DataProvider.getAllSchedules()
        this.schedulesList = data
  }
}
</script>

<style scoped>
        #overlay {
            width: 100%;
            min-height: 100%;
            background-color: rgba(0, 0, 0, 0.4); 
            display: flex;
            flex-direction: column;
        }
        #welcome_screen{
            min-height: 100%;
            width: 100%;
            background-image: url('../assets/img/background_cropped.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center left;
            background-attachment: fixed;
        }

        header{
            max-height: 15%;
            display: flex;
            justify-content: space-around;
            margin-top: 3rem;
        }
        header > p{
            color: rgb(255, 255, 255, 0.3);
            flex: 1;
            padding-top: 1.5rem;
            font-size: 2rem;
            
        }
        #p1{
            text-align: right;
        }
        #p2{
            text-align: left;
        }
        #logo{
            width: 20rem;
            padding: 0 1rem 0 1rem;
        }
        #welcome_screen_content{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-content: center;
            flex-wrap: wrap;
            padding-top: 15%;
        }
        button{
            border: 0;  
            background-color: rgb(0, 170, 255);  
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            font-weight: bold;
        }
        .new_element_button{
            height: 3rem;
            width: 20rem;  
            font-size: 1.5rem;
            box-shadow: 0.25rem 0.25rem 0px 0px #00000040;
        }
        #schedules_list{
            width: 20rem;
            background-color: rgb(255, 255, 255);
            margin-top: 1rem;
            box-shadow: 0.25rem 0.25rem 0px 0px #00000040;
        }
        #schedules_list > li{
            border: 0.25px solid rgb(230,230,230);
            padding: 0.25rem 0.25rem 0.25rem 0.75rem;
            font-size: 1rem;
            display: flex;
        }
        .delete_schedule_button_container {
            flex: 1;
            text-align: right;
        }
        .delete_schedule_button {
            color: #D90B61;
            border: none;
            background-color: inherit;
            font-size: 1rem;
            transform: rotate(45deg);
            transform-origin: bottom;
            height: 0.875rem;
            
        }
        #schedules_list > li > div > p{
            color: rgb(174,174,174);
            font-size: 0.75rem;
        }
        @media screen and (max-width: 900px) {
            header {
                flex-direction: column;
                align-items: center;
            }
            header > p {
                padding: 0;
            }
        }
        @media screen and (max-width: 360px) {
            header > #logo {
                width: 180px;
            }
            header > p {
                font-size: 20px;
            }
            #newScheduleFormDiv {
                width: 80%;
            }
            .new_element_button {
                font-size: 20px;
            }
            .new_element_button, #schedules_list {
                width: 80%;
            }
        }

</style>