<template>
    <div id="newScheduleFormDiv">
        <form @submit.prevent="conditionalHandleSubmit">
            <h2>Give new schedule a name</h2>
            <input type="text" id="newScheduleNameInput" v-model="newSchedule.data.name" spellcheck="false">
            <button type="submit">Submit</button>
        </form>
    </div>
</template>
<script>
    import DataProvider from '../../DataProvider'
    import Helpers from '../../Helpers'
    export default {
        data() {
            return  {
            newSchedule: {     
                data: {
                    name: null
                },
                trainings: [
                    {
                        id: Helpers.createId(10),
                        data: {
                            date: null,
                            type: null,
                            intensity: null,
                            time: null
                        },
                        exercises: [
                            {
                                id: null,
                                data: {
                                    name: null,
                                    type: null,
                                    parameters: {
                                        a: null,
                                        b: null,
                                        c: null
                                    }
                                }
                            }
                        ]

                    }
                ]
            }
        }
        },
        methods: {
            async conditionalHandleSubmit() {
                if (this.newSchedule.data.name !== null) {
                    this.handleSubmit();
                } else {
                    alert('Name is required')
                }
            },
            async handleSubmit() {
                    const response = await DataProvider.setSchedule(this.newSchedule)
                    this.$router.push({ name: 'ScheduleView', params: { scheduleUuid: `${response.uuid}`}})
            }            
        }
}
</script>

<style scoped>
    #newScheduleFormDiv{
        border: #E45490 solid 2px;
        background-color: rgb(255, 255, 255);
        position: absolute;
        transform: translate(-50%,-50%);
        top: 55%;
        left: 50%;
        height: 25%;
        width: 320px;
        padding: 16px;
        z-index: 1;
    }
    #newScheduleFormDiv > form {
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;
    }
    #newScheduleFormDiv > form > h2 {
        color: #E45490;
        font-size: 18px;
    }
    #newScheduleFormDiv > form > input {
        max-width: 80%;
        height: 40px;
        font-size: 24px;
        border: none;
        outline: none;
        background-color: rgb(242, 242, 242);
    }
    #newScheduleFormDiv > form > input:focus {
        box-shadow: 0 1px 0 0 #00AAFF;
    }
    #newScheduleFormDiv > form > button {
        height: 2.5rem;
        width: 5.5rem;
        font-size: 1.125rem;
        border: 0;  
        background-color: rgb(0, 170, 255);  
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-weight: bold;
    }

</style>
