<template>
    <header>
        <img src="../assets/img/logo_pink.svg" alt="Fizzrook">
    </header>
    <div class="loader" v-if="!this.schedule.uuid"></div>
    <div v-else id="content">
        <div class="section1">
            <nav >
                <router-link to="/">My Schedules</router-link>
                <p class="nav_slash">/</p><p class="nav_arrow">▼</p>
                <router-link :to="`/${scheduleUuid}`" class="current_nav_element"> {{ this.schedule.data ? this.schedule.data.name : 'Loading...' }} </router-link>
            </nav>
            <button type = "submit" @click = "saveTable" v-show="isScheduleNotEqualSavedSchedule">Save</button>
        </div>
        <div class="section3"> 
            <div class="pseudo_table">
                <div class="pseudo_table_head">
                    <span class="pseudo_table_head_cell">Date</span>
                    <span class="pseudo_table_head_cell">Type</span>
                    <span class="pseudo_table_head_cell">Intensity from 1 to 10</span>
                    <span class="pseudo_table_head_cell">Duration, minutes</span>
                    <span class="pseudo_table_cell_delete"></span>
                </div>
                <div class="pseudo_table_row" v-for="training in schedule.trainings" :key="training.id">
                    <div class="pseudo_table_cell">
                        <p v-if="training.data.date"> {{ trainingDateNav(training.data.date) }} </p>
                        <p v-else class="pseudoPlaceholder">09/22/2023</p>
                        <label>Date</label>
                    </div>
                    <div class="pseudo_table_cell">
                        <p v-if="training.data.type">{{ training.data.type }}</p>
                        <p v-else class="pseudoPlaceholder">Calisthenics</p>
                        <label>Type</label>
                    </div>
                    <div class="pseudo_table_cell">
                        <p v-if="training.data.intensity">{{ training.data.intensity }}</p>
                        <p v-else class="pseudoPlaceholder">5</p>
                        <label>Intensity</label>
                    </div>
                    <div class="pseudo_table_cell">
                        <p v-if="training.data.duration">{{ training.data.duration }}</p>
                        <p v-else class="pseudoPlaceholder">60</p>
                        <label>Duration</label>
                    </div>
                    <div class="pseudo_table_cell_delete">
                        <button class="edit_button" v-show="isScheduleSaved(training)" @click="goToTraining(training, training.id)">&#9998</button>
                        <div>
                            <button class="delete_button" @click = "deleteTrainig(training.id)">✕</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button class="add_new_table_element_button" @click = "addNewTraining">Add new training</button>
            </div>
        </div>
    </div>
</template>

<script>
    import DataProvider from '../DataProvider';
    import Helpers from '../Helpers'
    export default {
        data() {
            return {
                schedule: {}, 
                typesOfTrainings: DataProvider.typesOfTrainings,
                isSaveButtonActive: false,
                savedSchedule: {}
            };
        },
        async mounted() {
            const scheduleUuid = this.$route.params.scheduleUuid;
            this.schedule = await DataProvider.getSchedule(scheduleUuid);
            this.savedSchedule = Helpers.deepCopy(this.schedule)
        },
        methods: {
            async addNewTraining() {
                await DataProvider.addNewTraining(this.schedule) 
            },
            deleteTrainig(trainingId) {
                DataProvider.deleteTraining(this.schedule, trainingId)
            }
            ,
            async saveTable() {
                let content = document.getElementById('content')
                content.style.pointerEvents = 'none'
                await DataProvider.setSchedule(this.schedule);
                this.savedSchedule = Helpers.deepCopy(this.schedule)
                content.style.pointerEvents = 'auto'
            },
            async goToTraining(training, trainingId) {
                    this.training = training
                    this.$router.push({ name: 'TrainingView', params: { trainingId: trainingId} });
            },
            isScheduleSaved(training) {
                return this.savedSchedule.trainings.some(savedTraining => 
                    Helpers.deepEqual(training, savedTraining)
                );
            },
            trainingDateNav(date) {
                var date = new Date(date)                
                var day = String(date.getDate()).padStart(2, '0'); 
                var month = String(date.getMonth() + 1).padStart(2, '0');
                var year = date.getFullYear();
                var formattedDate = month + '/' + day + '/' + year;
                return formattedDate
            }
        },
        computed: {
            isScheduleNotEqualSavedSchedule() {
                return !Helpers.deepEqual(this.schedule, this.savedSchedule);
            },
        }
    };
</script>
<style scoped>
</style>