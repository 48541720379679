import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView'
import ScheduleView from '../views/ScheduleView'
import TrainingView from '../views/TrainingView'
const routes = [
  { path: '/', name: 'WelcomeView', component: WelcomeView },
  { path: '/:scheduleUuid', name: 'ScheduleView', component: ScheduleView },
  { path: '/:scheduleUuid/:trainingId', name: 'TrainingView', component: TrainingView}
,
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router 