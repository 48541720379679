const crypto = require('crypto-browserify');
class Helpers {
    constructor(){
    }
    static createId(length, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') {
        const selected = [];
        const maxRandomValue = characters.length * Math.floor(256 / characters.length);
        do {
            const randomValue = crypto.randomBytes(1).readUInt8();
            if (randomValue < maxRandomValue) {
            selected.push(randomValue % characters.length);
            }
        } while (selected.length < length);
        return selected.map(v => characters[v]).join('');
    }
    static deepEqual(object1, object2) {
        if (object1 === object2) {
            return true;
        }
        if (typeof object1 !== 'object' || object1 === null ||
            typeof object2 !== 'object' || object2 === null) {
            return false;
        }

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2.includes(key)) {
                return false;
            }

            if (!this.deepEqual(object1[key], object2[key])) {
                return false;
            }
        }

        return true;
    }
    static deepCopy(schedule) {
        return JSON.parse(JSON.stringify(schedule))
    }

    static dateStringObjectToggle(date) {
        if (typeof date === 'string') {
            return new Date(date) 
        }
        if (typeof date === 'object') {
            return 
        }
    }
}
module.exports = Helpers